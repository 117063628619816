import { createI18n } from 'vue-i18n';
import axios from 'axios';

export const i18n = createI18n({
  silentTranslationWarn: true,
  messageResolver: (obj, path) => obj[path]?.replaceAll('@', "{'@'}") ?? null,
});

const fetchTranslation = (locale, id) => axios.get(`/localization?locale=${locale}&company_id=${id}`);

export const setLocalization = (language, id = '') => {
  if (language) {
    localStorage.setItem('language', language);
  }

  const locale = localStorage.getItem('language') || navigator.language.split('-')[0];

  return fetchTranslation(locale, id)
    .then(({ data }) => {
      i18n.global.locale = locale;
      i18n.global.setLocaleMessage(locale, data);
    });
};

export const $t = i18n.global.t;
