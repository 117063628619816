import { $t } from '@/translations/index.js';

export default {
  setLoading(state, value) {
    state.isLoading = value;
  },
  setCompanyData(state, data) {
    state.companyData = data;
  },
  setEmailText(state) {
    state.companyData.returnReasons = state.companyData.returnReasons.map((reason) => ({
      ...reason,
      emailText: reason.emailTexts?.map((emailText) => $t(emailText)).join('. ') || '',
    }));
  },
  setError(state, value) {
    if (!value) {
      state.errorMessage = '';

      return;
    }

    if (value?.response?.status === 404) {
      state.errorMessage = 'error.order.not.found';
    } else {
      state.errorMessage = 'server.error';
    }
  },
};
