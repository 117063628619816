import axios from 'axios';
import { setLocalization } from '@/translations/index.js';

export default {
  fetchTranslations(_, id) {
    return axios.get(`/localization?company_id=${id}`);
  },
  getLocalizationItem({ dispatch }, { companyId, code }) {
    return dispatch('fetchTranslations', companyId)
      .then(({ data }) => data.find((translation) => translation.code === code));
  },
  postTranslations(_, payload) {
    return axios.post('/localization', { company_id: payload.company_id, localization: payload.translation })
      .then(() => {
        setLocalization(null, payload.company_id);
      });
  },
  resetCompanyLocalization(_, payload) {
    return axios.post('/localization/reset', payload)
      .then(() => {
        setLocalization(null, payload.company_id);
      });
  },
};
