import { $t } from '@/translations/index.js';

export default {
  offersTableHeaders() {
    return [
      { title: `${$t('value')}`, key: 'value' },
      { title: `${$t('return.offer.text')}`, key: 'text' },
      { title: `${$t('is.active')}`, key: 'isActive' },
      {
        title: `${$t('actions')}`, align: 'end', key: 'actions', sortable: false,
      },
    ];
  },
  reasonsTableHeaders() {
    return [
      { title: `${$t('reason.text')}`, key: 'text' },
      { title: `${$t('reason.code')}`, key: 'code', sortable: false },
      { title: `${$t('reason.externalCode')}`, key: 'externalCode', sortable: false },
      { title: `${$t('choices')}`, key: 'choices', sortable: false },
      { title: `${$t('return.email.texts')}`, key: 'emailTexts', sortable: false },
      { title: `${$t('returnable.period')}`, key: 'validDaysToAutoAcceptReturn', sortable: false },
      { title: `${$t('reason.isCreateFreeRedelivery')}`, key: 'isCreateFreeRedelivery', sortable: false },
      { title: `${$t('show.image.upload')}`, key: 'showImageUpload', sortable: false },
      { title: `${$t('create.credit.note')}`, key: 'isCreateCreditNote', sortable: false },
      {
        title: `${$t('actions')}`, align: 'end', key: 'actions', sortable: false,
      },
    ];
  },
  stepsTableHeaders() {
    return [
      { title: `${$t('return.step.text')}`, key: 'text' },
      {
        title: `${$t('actions')}`, align: 'end', key: 'actions', sortable: false,
      },
    ];
  },
  emailTextsTableHeaders() {
    return [
      { title: `${$t('return.email.text')}`, key: 'text' },
      {
        title: `${$t('actions')}`, align: 'end', key: 'actions', sortable: false,
      },
    ];
  },
};
